import React, {useEffect, useState} from 'react'
import {  Image   } from 'react-bootstrap';
import SideMenu from './SideMenu';
import { Link } from 'react-router-dom';
function NavBar() {
    const [scroll, setScroll] = useState(false);
    const [openSideBar, setOpenSideBar] = useState(false)
    const handleShowSideBar = () =>{
        setOpenSideBar(true)
    }
    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 150);
        });
    })
  return (    
    <>
    <div className={"menuNav " + (scroll ? "stickyMenu animated fadeInDown" : "staticMenu")}>   
    <div className="container_fl">     
      <div className="nav_item">
        <div className="nav_left">
            <Link to={"/"}>
              <Image className='img-fluid' src="/assets/images/logo.webp"/>
            </Link>
        </div>
        <div className="nav_right">
          <div className="hambergerMenu scrollHamberger showOnMobileHam" onClick={handleShowSideBar}>
              <span></span>
              <span></span>
              <span></span>
          </div>
        </div>
      </div>
    </div>
    </div>
    <div className="sideMenu mobile_menu">
        <SideMenu handleShowSideBar={handleShowSideBar} openSideBar={openSideBar} setOpenSideBar={setOpenSideBar}/>
    </div>
    </>
  )
}

export default NavBar