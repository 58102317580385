import React,{useState} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
function TextmeModal({showText,handleTextClose}) {
  return (
    <div>
        <Modal show={showText} onHide={handleTextClose}>    
        <Modal.Header className='mod_hdr'>
        <Modal.Title> <b>Text me the App</b></Modal.Title>
        <div className="modal_close">
            <span class="close"><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 33.931 33.93"><path id="noun-close-5279399" d="M121.967,35a16.984,16.984,0,1,0,11.991,4.975A16.966,16.966,0,0,0,121.967,35Zm5.708,20.952a1.217,1.217,0,0,1-1.721,1.721l-3.987-3.987-3.987,3.987a1.217,1.217,0,0,1-1.721-1.721l3.987-3.987-3.987-3.987a1.217,1.217,0,0,1,1.721-1.721l3.987,3.987,3.987-3.987a1.217,1.217,0,0,1,1.721,1.721l-3.987,3.987Z" transform="translate(-105.002 -35)" fill="#13243c" opacity="0.14"></path></svg></span>
        </div>
        </Modal.Header>
        <Modal.Body>
        <div className="body_modal">
                    <Form>
                        <fieldset>
                            <Form.Group className="mb-3">
                                <div className="c_fld">
                                    <span className='c_code'>+91</span>
                                    <Form.Control type="text" placeholder="Phone Number" />
                                </div>
                            </Form.Group>
                            <div className="col-md-7 text-center m-auto">
                                <Button type="submit" onClick={handleTextClose} className='btnpop'>Text me</Button>
                            </div>
                        </fieldset>
                    </Form>
                </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default TextmeModal