import React, { useState } from "react";
import { Modal } from "react-bootstrap";

const NewModal = ({ submitted, setSubmitted, handleClose }) => {
  const [show, setshow] = useState(true);

  const handleRemove = () => {
    setshow(false);
    handleClose();

    // console.log(submitted,'status of submitted');
  };
  return (
    <div>
      <Modal centered show={show} onHide={handleRemove}>
        <Modal.Body>
          <>
            <div className="body_modal submitted_body_modal ">
              <div className="success_top ">
                <span>
                  <svg
                    id="noun-tick-801900"
                    width="42.159"
                    height="42.159"
                    viewBox="0 0 42.159 42.159"
                  >
                    <path
                      id="Path_3893"
                      data-name="Path 3893"
                      d="M102.279,11.2a21.079,21.079,0,1,0,21.079,21.079A21.085,21.085,0,0,0,102.279,11.2Zm10.847,15.985L100.742,39.612a2.237,2.237,0,0,1-1.537.659,2.061,2.061,0,0,1-1.537-.659l-6.236-6.236a2.2,2.2,0,0,1,3.118-3.118l4.655,4.655,10.847-10.847a2.175,2.175,0,0,1,3.118,0,2.214,2.214,0,0,1-.044,3.118Z"
                      transform="translate(-81.2 -11.199)"
                      fill="#0ea47a"
                    />
                  </svg>
                </span>
                <p>
                  Congratulations. You have earned 50 reward points. We will
                  alert you once the app goes live!{" "}
                </p>
              </div>
              <div className="success_close">
                <button className="btn btn-sm" onClick={handleClose}>
                  Close
                </button>
              </div>
            </div>
          </>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default NewModal;
