import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import './App.css';
import './index.scss'
import Home from './Pages/Home';
import 'bootstrap/dist/css/bootstrap.css';
import './css/animate.css';
import FaqPage from "./Pages/FaqPage";
import Contact from "./Pages/Contact";
import Terms from "./Pages/Terms";
import Privacy from "./Pages/Privacy";
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home/>}>
          </Route>  
          <Route path="/faqs" element={<FaqPage/>}>
          </Route>  
          <Route path="/contact" element={<Contact/>}>
          </Route>  
          <Route path="/terms-conditions" element={<Terms/>}>
          </Route>  
          <Route path="/privacy" element={<Privacy/>}>
          </Route>  
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
