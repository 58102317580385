import { useState } from "react"
import { Link } from "react-router-dom"

export default function SideBarItem({ item }) {
    const [open, setOpen] = useState(false)
    // console.log(open);
    if (item?.childs) {
        return (
            <>
                <div className={open ? "sidebar-item open" : "sidebar-item"}>
                   <Link className="added_link" to={item.path}>
                   <div className="sidebar-title" >
                        <span>
                            {item.title} 
                        </span> 
                        <i className="bi-chevron-down toggle-btn"></i>
                    </div>
                   </Link>
                    <div className="sidebar-content">
                        { item.childs.map((child, index) => <div key={index}><SideBarItem  item={child} /></div>) }
                    </div> 
                </div>
            </>
        )
    }
    else {
        return (
            <>  {
                item?.path &&
                <>
                    <a href={item?.path || "#"} className="sidebar-item plain">
                        {item?.title}
                    </a>
                </>
            }

                {
                    item?.input &&
                    <>
                        <div className="check_filter">
                            <span><input type="checkbox" id={item?.input} /></span>
                            <label htmlFor={item?.input}>{item?.label}</label>
                        </div>
                    </>
                }
                {
                    item?.code &&
                    <>
                        <div className="color-item">
                            <span className={item?.color === "White" ? "has-border" : "no-border"}><i style={{ backgroundColor: item.code }}></i></span>
                            <span>{item?.color}</span>
                        </div>
                    </>
                }

            </>


        )
    }
}