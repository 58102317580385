import React from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
function Footer() {
    return (
        <div>
            <footer>
                <Container>
                    <div className='fc_row'>
                        <div className='fc_left'>
                            <ul>
                                <li className='me-4'><a href="/terms-conditions">Terms & Conditions</a></li>
                                <li className='me-4'><a href="/privacy">Privacy Policy</a></li>
                                <li className='me-0'><a href="/faqs">FAQ</a></li>
                            </ul>
                        </div>
                        <div className='fc_right'>
                            <ul>
                                <li className='me-xl-5 me-md-0 me-sm-0 me-xs-0 mt-1 mb-2'>
                                <Link to={"/contact"}>
                                    <span className='btn_border px-2 pb-0'>Contact Us</span>
                                    </Link>
                                </li>


                                {/* <li className='me-5'><a href="#">  <Image className='img-fluid' src="/assets/images/twitter.svg"/></a></li>
            <li className='me-5'><a href="#">  <Image className='img-fluid' src="/assets/images/facebook.svg"/></a></li>
            <li className='me-5'><a href="#">  <Image className='img-fluid' src="/assets/images/instagram.svg"/></a></li>
            <li className='me-5'><a href="#">  <Image className='img-fluid' src="/assets/images/linkedin.svg"/></a></li> */}

                            </ul>
                        </div>
                    </div>

                </Container>
            </footer>
        </div>
    )
}

export default Footer