import React from 'react';
import { Accordion } from 'react-bootstrap';
import { Link } from 'react-router-dom';
function Faqs() {
    return (
        <div>
            <div className="faqsSec pt100 pb60">
                <div className="container">
                    <div className="secHd">
                        <div className='text-center'>

                            <h2><b>Frequently Asked Questions</b></h2>

                        </div>
                        <div className="contact-cta text-center pt-0">
                            <p>Have question ? We're here to help</p>
                            <Link to={"/contact"}>
                                <button>Contact Us</button>
                            </Link>
                        </div>
                    </div>

                    <Accordion defaultActiveKey="0" flush className="faqAcc">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>What is Hedge Rewards?</Accordion.Header>
                            <Accordion.Body>
                                Hedge Rewards is a mobile app that provides users with an innovative way to turn their retail sports gambling bet slips into valuable reward points. By using the app's camera feature to scan retail bet slips, you can convert your gambling wagers into points that can be redeemed for various rewards.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>How does the app work?</Accordion.Header>
                            <Accordion.Body>
                                Using the Hedge Rewards app is simple. After placing a bet at a retail sportsbook location, you can use the app's camera feature to take a photo of your bet slip. The app will then automatically convert the amount you've wagered into reward points, which you can later redeem in our online marketplace.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>What can I do with the reward points earned?</Accordion.Header>
                            <Accordion.Body>
                                The reward points you earn by scanning bet slips can be redeemed in our online marketplace. We offer a variety of rewards, including gift cards, concert tickets, hotel accommodations, and more. The more you scan, the more points you accumulate, increasing your reward options.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>How do I download the app?</Accordion.Header>
                            <Accordion.Body>
                                You can download the Hedge Rewards app from the App Store (for iOS) or the Google Play Store (for Android). Simply search for "Hedge Rewards" and follow the installation instructions.
                            </Accordion.Body>
                        </Accordion.Item>


                        <Accordion.Item eventKey="4">
                            <Accordion.Header>Is the app free to use?</Accordion.Header>
                            <Accordion.Body>
                                Yes, the Hedge Rewards app is free to download and use. There are no hidden charges or subscription fees.
                            </Accordion.Body>
                        </Accordion.Item>


                        <Accordion.Item eventKey="5">
                            <Accordion.Header>How accurate is the bet slip scanning feature?</Accordion.Header>
                            <Accordion.Body>
                                Our app is built with advanced image recognition technology to ensure accurate scanning of bet slips. However, it's important to ensure that the bet slip is captured clearly and legibly to ensure accurate conversion.
                            </Accordion.Body>
                        </Accordion.Item>


                        <Accordion.Item eventKey="6">
                            <Accordion.Header>How long does it take for my reward points to appear in the app?</Accordion.Header>
                            <Accordion.Body>
                                Typically, reward points are added to your account shortly after successfully scanning a bet slip. However, processing times may vary based on factors such as network speed and server load.
                            </Accordion.Body>
                        </Accordion.Item>


                        <Accordion.Item eventKey="7">
                            <Accordion.Header>Is my personal information secure when using the app?</Accordion.Header>
                            <Accordion.Body>
                                We prioritize the security and privacy of our users. Your personal information is encrypted and protected. We do not share your data with third parties without your consent.
                            </Accordion.Body>
                        </Accordion.Item>


                        <Accordion.Item eventKey="8">
                            <Accordion.Header>How do I get assistance if I encounter issues with the app?</Accordion.Header>
                            <Accordion.Body>
                                If you experience any technical difficulties or have questions, you can reach out to our customer support team directly from within the app. We're here to assist you.
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="9">
                            <Accordion.Header>Are there any restrictions on who can use the app?</Accordion.Header>
                            <Accordion.Body>
                                Users must be of legal age to gamble in their respective jurisdictions to use the app.
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </div>
        </div>
    );
}

export default Faqs;