import React from 'react'
import Faqs from '../Componants/Faqs'
import NavBar from '../Componants/NavBar'
import Footer from '../Componants/Footer'

function FaqPage() {
  return (
    <div>
        <NavBar/>
        <Faqs/>
        <Footer/>
    </div>
  )
}

export default FaqPage