import React from 'react'
import NavBar from '../Componants/NavBar'
import Footer from '../Componants/Footer'

function Terms() {
  return (
    <div>
        <NavBar/>
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-8 col-sm-12 col-lg-10">
                <div className="pg_inside text-left">
            <h2>Terms & Conditions</h2>
            <h5>Effective Date: [Date]
                <br/> <br/>
Thank you for visiting hedgerewards.com (the &quot;Website&quot;). These Terms and Conditions
govern your access to and use of the Website, as well as your download and use of the
mobile application (&quot;App&quot;). By accessing the Website, downloading the App, and
signing up for bonus points, you agree to comply with these Terms and Conditions. If
you do not agree with any part of these Terms and Conditions, please refrain from using
the Website and downloading the App.</h5>
            <div className="pg_con">
                <h3>1. Age Restriction</h3>
                <p>1.1 Eligibility You must be at least 21 years old to download and use the App. By
downloading the App and signing up for bonus points, you represent and warrant that
you are at least 21 years old.</p>
                <p>1.2 Age Verification We may require you to verify your age before accessing certain
features of the App or receiving bonus points. You agree to provide accurate and
truthful information for age verification purposes.</p>
                <h3>2. Use of the App</h3>
                <p>2.1 License Subject to your compliance with these Terms and Conditions, we grant you
a limited, non-exclusive, non-transferable, revocable license to download, install, and
use the App for personal, non-commercial purposes. This license does not grant you
any ownership rights to the App..</p>
                <p>2.2 Account Creation To access certain features of the App and receive bonus points,
you may be required to create an account. When creating an account, you agree to
provide accurate and complete information, including your first name, last name, and
email address. You are solely responsible for maintaining the confidentiality of your
account credentials and for all activities that occur under your account. You must notify
us immediately of any unauthorized use or suspected breach of security of your
account.</p>
                <h3>3. Bonus Points and Rewards</h3>
                <p>3.1 Sign Up Bonus By signing up for the App through the Website and providing your
first name, last name, and email address, you may be eligible to receive bonus points.
These bonus points may be redeemed for rewards as specified within the App. The
accumulation and redemption of bonus points are subject to the terms and conditions
outlined in the App.</p>
                <p>3.2 Reward Availability The availability of rewards may vary and is subject to change
without notice. We make no guarantees regarding the availability or specific details of
any rewards offered through the App.</p>
                <h3>4. Intellectual Property</h3>
                <p>Ownership All intellectual property rights in the Website, App, and their contents
(including but not limited to text, graphics, logos, images, audio, and video materials)
are owned by or licensed to us. These materials are protected by copyright, trademark,
and other intellectual property laws. You may not reproduce, distribute, modify, display,
perform, or use any intellectual property without our prior written consent.</p>
<h3>5. Limitation of Liability</h3>
                <p>5.1 Disclaimer of Warranties The Website and the App are provided on an &quot;as-is&quot; and
&quot;as available&quot; basis without any warranties or representations, express or implied. We
disclaim all warranties, including but not limited to, warranties of merchantability, fitness
for a particular purpose, non-infringement, or availability of the Website and the App
without interruption or error-free.</p>
<p>5.2 Limitation of Liability To the maximum extent permitted by applicable law, we shall
not be liable for any direct, indirect, incidental, consequential, or special damages
arising out of or in connection with your use of the Website or the App, regardless of the
cause of action or theory of liability (including negligence).</p>
<h3>6. Modification and Termination</h3>
<p>6.1 Modification We reserve the right to modify, suspend, or discontinue the Website or
the App at any time without prior notice.</p>
<p>6.2 Termination We may terminate or suspend your access to the Website or the App,
in whole or in part, without prior notice or liability, for any reason whatsoever, including
without limitation if you breach these Terms and Conditions.</p>
<h3>7. Governing Law and Dispute Resolution</h3>
<p>7.1 Governing Law These Terms and Conditions shall be governed by and construed in
accordance with the laws of [Jurisdiction], without regard to its conflict of laws principles.</p>
<p>7.2 Dispute Resolution Any disputes arising out of or relating to these Terms and
Conditions shall be submitted to binding arbitration in accordance with the rules of
[Arbitration Provider]. The arbitration shall take place in [Location], and the arbitrator&#39;s
decision shall be final and binding.</p>
<h3>8. Miscellaneous</h3>
<p>8.1 Severability If any provision of these Terms and Conditions is held to be invalid or
unenforceable, the remaining provisions shall continue to be valid and enforceable to
the fullest extent permitted by law. </p>
<p>8.2 Entire Agreement These Terms and Conditions constitute the entire agreement
between you and us concerning the Website, the App, and the sign-up for bonus points,
and they supersede all prior or contemporaneous understandings or agreements.</p>
<h3>9. Contact Us</h3>
<p>If you have any questions or concerns about these Terms and Conditions, please
contact us at info.hedgerewards.com</p>
<h3 className='text-center'>By using the Website, downloading the App, and signing up for bonus points, you agree
to abide by these Terms and Conditions.</h3>
            </div>
        </div>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
  )
}

export default Terms