import React, { useState } from 'react';
import { Container, Image } from 'react-bootstrap';
import ReactPlayer from 'react-player';
import Footer from '../Componants/Footer';
import NavBar from '../Componants/NavBar';
import RegisterModal from '../Componants/RegisterModal';
import TextmeModal from '../Componants/TextmeModal';
import { Link } from 'react-router-dom';



function Home() {
    const [show, setShow] = useState(false);
    const [showText, setShowText] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleTextClose = () => setShowText(false);
    const handleTextShow = () => setShowText(true);
    return (
        <>
            {
                showText &&
                <TextmeModal showText={showText} handleTextClose={handleTextClose} handleTextShow={handleTextShow} />
            }
            {
                show &&
                <RegisterModal show={show} handleClose={handleClose} handleShow={handleShow} />
            }
            <NavBar />
            <Container fluid className='p-0'>
                <div className='banner text-center text-md-start'>
                    <div className='row m-0 align-items-center '>
                        <div className='col-12 col-lg-5'>
                            <div className='banne_left'>
                                <h1 className='mt-xl-5 mt-sm-2 mt-md-2 mt-3 mb-2'>Welcome to Hedge Rewards </h1>
                                <p className='mb-sm-5 mb-md-0 mb-xl-4       pb-sm-5 pb-md-0 pb-xl-4 '>The future of sports betting is here! Hedge Rewards is the only universal rewards program for in-person sports wagering. Our easy-to-use application platform allows sports betters  to scan retail bet slips and convert the amount wagered into reward points. These points can then be redeemed through our online market place for amazing rewards and exclusive perks.

                                    <br /><br />Its time to elevate your betting experience and maximize your potential winnings with Hedge Rewards! </p>
                                <p className='pt-3 pt-md-4 com_text mb-2'><b>App coming soon to IOS and Android</b></p>
                                <div className='str_redirect mb-3 mb-sm-5 mb-md-0 mb-xl-0'>
                                    <Link to={"https://play.google.com/store/games"} target="_blank">
                                        <Image className='img-fluid mb-2 mb-sm-2 mb-md-2 mb-xl-5 custom_store' src="/assets/images/playstore.svg" />
                                    </Link>                        
                                    <Link to={"https://www.apple.com/in/app-store/"} target="_blank">
                                        <Image className='img-fluid mb-2 mb-sm-2 mb-md-2 mb-xl-5 custom_store' src="/assets/images/appstore.svg" />
                                    </Link>            
                                    
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-lg-7 p-0'>
                            <Image className='img-fluid banner_img' src="/assets/images/banner.webp" />
                        </div>

                    </div>
                </div >
                <div className='register'>
                    <Container fluid className='py-4 '>
                        <div className='row align-items-center '>
                            <div className='col-12 col-lg-7 pe-5 me-5'>
                                <div className='ml_custom'>
                                    <h2>Earn 50 Free Reward Points!</h2>
                                    <p className='mb-0'>As a special bonus, we are offering 50 free reward points to kickstart your account in the app.
                                        Simply sign up on our website, and these bonus points will be credited to your Hedge Rewards account. Don't miss out on this limited-time offer! </p>
                                </div></div>
                            <div className='col-12 col-lg-4  ms-xl-4 ms-md-0 ms-sm-0 ms-xs-0'>
                                <div className="rg_rt my-4 text-md-center  text-center">
                                    <button className='regBtn' onClick={handleShow}>Sign Up Now!</button>{' '}
                                </div>
                            </div>
                        </div>
                    </Container>

                </div>


            </Container >
            <section className="welcome_sec">
                <Container className='my-5 my-md-5'>
                    <div className='hed-sec'>
                        <h2 className='text-center'>How Hedge Rewards Works </h2>
                        <p>A revolutionary software platform that is designed with simplicity in mind, making it easy for anyone to use. Start earning rewards in 3 simple steps!</p>
                    </div>
                    <div className='row mt-3 mt-md-5'>
                        <div className='col-12 col-md-4 text-center inner-text mb-3'>
                            <Image className='img-fluid wd_custom1' src="/assets/images/step1.svg" />
                            <div className='position_djst'>
                                <h3>Place a Bet</h3>
                                <p>Make a sports bet in person at any nearby Sportsbook. Please bet responsibly.</p>
                            </div></div>


                        <div className='col-12 col-md-4 text-center inner-text mb-3'>
                            <Image className='img-fluid wd_custom1' src="/assets/images/step2.svg" />
                            <div className='position_djst'>    <h3>Scan your Bet Slip</h3>
                                <p>Open the camera feature through the app to scan the front end of your bet slip.</p>
                            </div></div>


                        <div className='col-12 col-md-4 text-center inner-text mb-3'>
                            <Image className='img-fluid wd_custom1' src="/assets/images/step3.svg" />
                            <div className='position_djst'> <h3>Earn Rewards</h3>
                                <p>Every time you scan a bet slip you earn reward points. Cash these points out for prizes in our marketplace!</p>
                            </div></div>
                    </div>

                </Container>
            </section>

            <div className='light-bgcolor py-3 py-md-5 '>
                <div className="seal_img">
                    <Image className='img-fluid' src="/assets/images/seal.webp" />
                </div>
                <Container className=''>
                    <div className='hed-sec'>
                        <h2 className='text-center'>Hedge Rewards Features </h2>
                        <p>Our platform is proud to introduce a range of patent-pending features that will re-invent the way you earn and redeem points, taking your rewards experience to unmatched heights.</p>
                    </div>
                    <div className='row mt-3 mt-md-5 justify-content-center'>
                        <div className='col-12 col-md-5 text-center inner-text inner-text-2 mb-3'>
                            <Image className='img-fluid wd_custom1' src="/assets/images/list2.svg" />
                            <div className='position_djst'>
                                <h3>Create an Account</h3>
                                <p>It's completely free and takes only a few minutes. Your personal information is safe and secure with our advanced security features.</p>
                            </div></div>
                        <div className='col-12 col-md-5 text-center inner-text inner-text-2 mb-3'>
                            <Image className='img-fluid wd_custom1' src="/assets/images/sc1.svg" />
                            <div className='position_djst'>
                                <h3>Scanning Technology</h3>
                                <p>Our cutting-edge technology will scan your receipt and automatically add points to your account.</p>
                            </div></div>

                    </div>

                </Container>
            </div>
            <Container className='my-3 my-md-5'>
                <div className='hed-sec'>
                    <h2 className='text-center'>Hedge Rewards Features</h2>
                    <p>Our innovative features have changed the way you engage retail sports betting. Hedge Rewards is the ultimate tool for success. Win or lose, we reward you for your passion.</p>
                </div>
                <div className='row mt-3 mt-md-5'>
                    <div className='col-12 col-md-4 text-center inner-text mb-3'>
                        <Image className='img-fluid wd_custom1' src="/assets/images/3-1.svg" />
                        <div className='position_djst'>
                            <h3>Online Marketplace</h3>
                            <p>Our marketplace is designed to provide you with a seamless and gratifying experience as you redeem your hard-earned points for a wide range of enticing products, services, and experiences.</p>
                        </div></div>
                    <div className='col-12 col-md-4 text-center inner-text mb-3'>
                        <Image className='img-fluid wd_custom1' src="/assets/images/3-2.svg" />
                        <div className='position_djst'> <h3>Find Local Sportsbooks</h3>
                            <p>Find local sportsbooks within a 100-mile radius. Discover nearby establishments where you can place your bets, enjoy the electrifying atmosphere of a sportsbook, and engage with fellow enthusiasts.</p>
                        </div></div>
                    <div className='col-12 col-md-4 text-center inner-text mb-3'>
                        <Image className='img-fluid wd_custom1' src="/assets/images/3-3.svg" />
                        <div className='position_djst'> <h3>Track your Bets</h3>
                            <p>Every bet slip scanned and accepted will be saved to your account as a tool for personal financial tracking and documentation.</p>
                        </div></div>
                </div>

            </Container>

            <div className='primary-bg sec_testimonials'>
                <Container className='py-3 py-md-5'>
                    <div className='row justify-content-center'>
                        <div className='col-12 col-md-10 col-lg-8 '>
                            <div className='hed-sec'>
                                <h2 className='text-center text-white mb-4'>Hedge Rewards Preview </h2>
                            </div>


                            <div className='col-lg-12 videostyle'>


                                <ReactPlayer url="https://hedgelive.s3.amazonaws.com/videos/HR_final_4K.mp4" rounded className="react-player rounded-border" controls playing width="100%" height="483px" light="assets/images/cover.png" />
                            </div>



                        </div>
                    </div>
                </Container>
            </div>

            <Footer />
        </>

    )
}

export default Home