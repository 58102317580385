import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
// import Form from 'react-bootstrap/Form';
import {  useForm } from "react-hook-form";
import Form from 'react-bootstrap/Form';
import { Alert, Spin } from 'antd';
import { RegisterUser } from '../Redux/Features/Mainslice';
import { useDispatch, useSelector } from 'react-redux';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
// import { Checkbox, Form, Input } from 'antd';
import NewModal from './NewModal';
function RegisterModal({ show, handleClose }) {
    const [submitted, setSubmitted] = useState(false)
    // const [form] = Form.useForm();
    const [Error, setError] = useState({
        value: false,
        message: ''
    })

    const { register, handleSubmit, formState: { errors } } = useForm();
    const { loading } = useSelector(state => state.data)
    const dispatch = useDispatch()
    // const onFinish = (values) => {
    //     console.log('Received values of form: ', values);
    //     form.resetFields();
    // };
    // console.log(user,'user');
    const onSubmit = data => {
        console.log(data);
        dispatch(RegisterUser(data)).then((res) => {
            // console.log(res,'after data fetch');
            if (res?.payload?.data?.message === 'Created') {
                setSubmitted(true)
            } else {
                // message.error(res?.payload)
                setError({
                    value: true,
                    message: res?.payload
                })
            }
        })



    };

    return (
        <div className='sidemenu_wrap'>
        <div className='mt-6'>
            {!submitted &&

                <Modal centered show={show} onHide={handleClose}>

                    <Modal.Header className='mod_hdr'>
                        <Modal.Title> <b>Signup</b></Modal.Title>
                        <div className="modal_close" onClick={handleClose}>
                            <span class="close"><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 33.931 33.93"><path id="noun-close-5279399" d="M121.967,35a16.984,16.984,0,1,0,11.991,4.975A16.966,16.966,0,0,0,121.967,35Zm5.708,20.952a1.217,1.217,0,0,1-1.721,1.721l-3.987-3.987-3.987,3.987a1.217,1.217,0,0,1-1.721-1.721l3.987-3.987-3.987-3.987a1.217,1.217,0,0,1,1.721-1.721l3.987,3.987,3.987-3.987a1.217,1.217,0,0,1,1.721,1.721l-3.987,3.987Z" transform="translate(-105.002 -35)" fill="#13243c" opacity="0.14"></path></svg></span>
                        </div>
                    </Modal.Header>


                    <Modal.Body >
                        {
                            !submitted &&
                            <div className="body_modal">
                                <Form onSubmit={handleSubmit(onSubmit)}>
                                    <fieldset>
                                        <Form.Group className="mb-3">
                                            
                                            <Form.Control  {...register("f_Name",
                                                {
                                                    onChange:()=> setError({value:false}) ,
                                                    required: 'First Name is Required',
                                                   
                                                    maxLength: {
                                                        value: 90,
                                                        message: 'Must be less than 90 characters'
                                                    },
                                                    minLength: {
                                                        value: 3,
                                                        message: 'Must be atleast 3 characters'
                                                    },
                                                    pattern: {
                                                        value: /^\S*$/,
                                                        message: "The field cannot be blank spaces only"
                                                    },
                                                }
                                            )} name='f_Name' type="text" placeholder="First Name" />
                                            {errors?.f_Name?.type === 'maxLength' && <Alert message={errors?.f_Name.message} type="error" />}
                                            {errors?.f_Name?.type === 'required' && <Alert message={errors?.f_Name.message} type="error" />}
                                            {errors?.f_Name?.type === 'pattern' && <Alert message={errors?.f_Name.message} type="error" />}
                                            {errors?.f_Name?.type === 'minLength' && <Alert message={errors?.f_Name.message} type="error" />}
                                            
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Control {...register("l_Name",
                                                {
                                                    required: 'Last Name is Required',
                                                    onChange:()=> setError({value:false}) ,
                                                    
                                                    pattern: {
                                                        value: /^[^\s]/,
                                                        message: "value cant contain  white space"
                                                    },
                                                    maxLength: { value: 90, message: 'Must be less than 90 characters' },
                                                    minLength: {
                                                        value: 3,
                                                        message: 'Must be atleast 3 characters'
                                                    },
                                                }
                                            )} type="text" name='l_Name' placeholder="Last Name"    />
                                            {errors?.l_Name?.type === 'maxLength' && <Alert message={errors?.l_Name.message} type="error" />}
                                            {errors?.l_Name?.type === 'required' && <Alert message={errors?.l_Name.message} type="error" />}
                                            {errors?.l_Name?.type === 'pattern' && <Alert message={errors?.l_Name.message} type="error" />}
                                            {errors?.l_Name?.type === 'minLength' && <Alert message={errors?.l_Name.message} type="error" />}
                                         
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Control {...register("email",
                                                {
                                                    onChange:()=> setError({value:false}) ,
                                                    required: 'E-mail is Required',
                                                    // validate: (value) => !value.trim() === "",
                                                    pattern: {
                                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: 'Invalid E-mail Address',

                                                    }


                                                }

                                            )} type="email" name='email' placeholder="Email" />

                                            {errors?.email && <Alert message={errors?.email.message} type="error" />}
                                            {errors?.email?.type === 'validate' && <Alert message='E-mailis Required' type="error" />}
                                            {!errors?.email && Error.value && <Alert message={Error?.message} type="error" />}
                                        </Form.Group>
                                        <div className="col-md-8 text-center m-auto">
                                            <Spin spinning={loading}>
                                                <Button className='btnpop' type="submit" >Submit</Button>
                                            </Spin>


                                        </div>
                                    </fieldset>
                                </Form>

                                {/* <Form
                                    form={form}
                                    name="normal_login"
                                    className="login-form"
                                    initialValues={{
                                        remember: true,
                                    }}
                                    onFinish={onFinish}
                                >
                                    <Form.Item
                                        name="First_Nme"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please Enter Your FirstName!',
                                            },
                                            {
                                                whitespace: true,
                                                message: 'FirstName Required'
                                            }
                                        ]}
                                    >
                                        <Input
                                            //  prefix={<UserOutlined className="site-form-item-icon" />} 
                                            placeholder="FirstName" />
                                    </Form.Item>
                                    <Form.Item
                                        name="Last_Nme"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please Enter Your LastName!',
                                            },
                                            {
                                                whitespace: true,
                                                message: 'LastName Required'
                                            }
                                        ]}
                                    >
                                        <Input
                                            //  prefix={<UserOutlined className="site-form-item-icon" />}
                                            placeholder="LastName" />
                                    </Form.Item>
                                    <Form.Item
                                        name="email"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please Enter Your E-mail!',
                                            },
                                            {
                                                type: 'email',
                                                message: 'Enter valid Email'
                                            }
                                        ]}
                                    >
                                        <Input
                                            //   prefix={<LockOutlined className="" />}
                                            type="email"
                                            placeholder="E-mail"
                                        />
                                    </Form.Item>


                                    <Form.Item>
                                        <Button type="primary" size='sm' htmlType="submit" className="login-form-button">
                                            Submit
                                        </Button>

                                    </Form.Item>
                                </Form> */}
                            </div>
                        }


                    </Modal.Body>
                </Modal>}
            {
                submitted && <NewModal submitted={submitted} setSubmitted={setSubmitted} handleClose={handleClose} />
            }

        </div>
        </div>
    )
}

export default RegisterModal