import React from 'react'
import items from "../data/menus.json"
import SidebarItem from './SideBarItem'
function SideMenu({ openSideBar, setOpenSideBar }) {
    return (
        openSideBar &&
        <div className='sidemenu_wrap'>
            <div className="sidebar">
                <div className="sidebar-header">
                    <span className="close" onClick={() => setOpenSideBar(false)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 33.931 33.93">
                            <path id="noun-close-5279399" d="M121.967,35a16.984,16.984,0,1,0,11.991,4.975A16.966,16.966,0,0,0,121.967,35Zm5.708,20.952a1.217,1.217,0,0,1-1.721,1.721l-3.987-3.987-3.987,3.987a1.217,1.217,0,0,1-1.721-1.721l3.987-3.987-3.987-3.987a1.217,1.217,0,0,1,1.721-1.721l3.987,3.987,3.987-3.987a1.217,1.217,0,0,1,1.721,1.721l-3.987,3.987Z" transform="translate(-105.002 -35)" fill="#13243c" opacity="0.14" />
                        </svg>
                    </span>
                </div>
                <div className="sidebar_wrap">
                    {items.map((item, index) => <SidebarItem key={index} item={item} />)}
                </div>
            </div></div>
    )
}

export default SideMenu