import React, { useState } from 'react'
import NavBar from '../Componants/NavBar'
import Footer from '../Componants/Footer'
import { Link } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useForm } from "react-hook-form";
import { Alert, message } from 'antd';
import { useDispatch } from 'react-redux';
import { ContactUs } from '../Redux/Features/Mainslice';
function Contact() {
    const { register,reset, handleSubmit, formState: { errors } } = useForm();
    const [Error, setError] = useState({
        value: false,
        message: ''
    })
    const dispatch =useDispatch()
    const onSubmit = data => {
        dispatch(ContactUs(data)).then((response)=>{
            // console.log(response,'response after creating');

            if (response?.type==="auth/contactUs/fulfilled") {
                message.success('Message has been submitted successfully')
                reset()
            } else {
                
            }
        })
    }
  return (
    <>    
         <NavBar />
            <div className="contact_detail">
                <div className="container">








                    <div className="row justify-content-center">

                        <div className="col-lg-6">

                            <div className='text-center contact-text'>
                                <h1><b>Contact Us</b></h1>

                                <p className='mb-5'> Great vision without great people is irrelevant. <br />Let's work together. </p></div>
                            <div className="body_form">

                            <Form onSubmit={handleSubmit(onSubmit)}>
                            <fieldset>
                                <Form.Group className="mb-3">
                                <Form.Control  {...register("f_Name",
                                            {
                                                required: 'Name is Required',
                                                // validate: (value) => !value.trim() === " ",
                                                maxLength: { value: 90, message: 'Must be less than 90 characters' },
                                                minLength:{value:3, message: 'Must be atleast 3 characters'},
                                                pattern: {
                                                    value: /^[^\s]/,
                                                    message: "The field cannot be blank spaces only"
                                                },
                                            }
                                        )} name='f_Name' type="text" placeholder="Name" className='p-4 ' />
                                        {errors?.f_Name?.type === 'maxLength' &&  <Alert message={errors?.f_Name.message} type="error" />}
                                        {errors?.f_Name?.type === 'required' &&  <Alert message={errors?.f_Name.message} type="error" />}
                                        {errors?.f_Name?.type === 'minLength' &&  <Alert message={errors?.f_Name.message} type="error" />}
                                        {errors?.f_Name?.type === 'pattern' &&  <Alert message={errors?.f_Name.message} type="error" />}
                                        {/* {errors?.f_Name?.type === 'validate' && <p className='text-danger'>Fisrt Name is Required</p>} */}
                                </Form.Group>
                                <Form.Group className="mb-3">
                                <Form.Control {...register("email",
                                                {
                                                    onChange:()=> setError({value:false}) ,
                                                    required: 'E-mail is Required',
                                                    // validate: (value) => !value.trim() === "",
                                                    pattern: {
                                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: 'Invalid E-mail Address',

                                                    }


                                                }

                                            )} type="email" name='email' placeholder="Email" className='p-4 ' />

                                            {errors?.email && <Alert message={errors?.email.message} type="error" />}
                                            {errors?.email?.type === 'validate' && <Alert message='E-mailis Required' type="error" />}
                                            {!errors?.email && Error.value && <Alert message={Error?.message} type="error" />}
                                    
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">

                                <Form.Control {...register("message",
                                            {
                                                required: 'Message is Required',
                                                // validate: (value) => !value.trim() === " ",
                                                maxLength: { value: 90, message: 'Must be less than 90 characters' },
                                                minLength:{value:3, message: 'Must be atleast 3 characters'},
                                                pattern: {
                                                    value: /^[^\s]/,
                                                    message: "The field cannot be blank spaces only"
                                                },
                                            }
                                        )} type="text"  as="textarea" rows={4} name='message' placeholder="Message" className='p-4' />
                                        {errors?.message?.type === 'maxLength' &&  <Alert message={errors?.message?.message} type="error" />}
                                        {errors?.message?.type === 'required' &&  <Alert message={errors?.message?.message} type="error" />}
                                        {errors?.message?.type === 'minLength' && <Alert message={errors?.message?.message} type="error" />}
                                        {errors?.message?.type === 'pattern' &&  <Alert message={errors?.message?.message} type="error" />}
                                        {/* {errors?.message?.type === 'validate' && <p className='text-danger'>Message is required</p>} */}

                                   
                                
                                    
                                    {/* <Form.Control {...register("message")} as="textarea" rows={3} placeholder='Message'  name='message'/>
                                    {errors.message && <span className='color-red'>{errors?.message?.message}</span>} */}
                                </Form.Group>
                                <div className="col-md-4 m-auto">
                                    <Button className='cst_button' type="submit">Submit</Button>
                                </div>
                            </fieldset>
                        </Form>
                            </div>
                        </div>
                    </div>






                    {/* <div className="row justify-content-center">
                        <div className="col-lg-4">



                            <div className="cd_left">
                                <h2><b>Contact Us</b></h2>
                                <div className="cdl_dec">
                                    <span>Our mailing address is: </span>
                                    <p>
                                        <span>1234 Charlotte1 Street,</span>
                                        <span> Peterborough ON </span>
                                        <span>Phone: 705-742-3221</span>
                                    </p>
                                    <div className="ss_icons">
                                        <div className="ss_item">
                                            <Link to="Components/Components.html">
                                                <span>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 19.538">
                                                        <path id="twitter" d="M26,6.19a10.188,10.188,0,0,1-2.832.768A4.956,4.956,0,0,0,25.34,4.234a9.852,9.852,0,0,1-3.132,1.2,4.92,4.92,0,0,0-8.4,4.488A13.968,13.968,0,0,1,3.668,4.774a4.992,4.992,0,0,0-.66,2.484A4.908,4.908,0,0,0,5.192,11.35a4.86,4.86,0,0,1-2.232-.612v.06a4.92,4.92,0,0,0,3.96,4.8,4.716,4.716,0,0,1-1.32.2,5.88,5.88,0,0,1-.924-.084,4.932,4.932,0,0,0,4.6,3.408A9.864,9.864,0,0,1,3.2,21.238,9.517,9.517,0,0,1,2,21.166a13.884,13.884,0,0,0,7.548,2.22A13.908,13.908,0,0,0,23.6,9.37V8.734A10.116,10.116,0,0,0,26,6.19Z" transform="translate(-2 -3.849)" fill="#13243c" />
                                                    </svg>
                                                </span>
                                            </Link>
                                        </div>
                                        <div className="ss_item">
                                            <Link to="Components/Components.html">
                                                <span>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 12.141 23.371">
                                                        <path id="facebook" d="M16.554,5.88h2.2V2.164A30.511,30.511,0,0,0,15.549,2C12.371,2,10.2,3.94,10.2,7.492v3.062H6.61v4.16H10.2V25.371h4.3V14.714h3.576l.538-4.16H14.5V7.9C14.5,6.674,14.825,5.88,16.554,5.88Z" transform="translate(-6.61 -2)" fill="#13243c" />
                                                    </svg>
                                                </span>
                                            </Link>
                                        </div>
                                        <div className="ss_item">
                                            <Link to="Components/Components.html">
                                                <span>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 23.046 23.046">
                                                        <path id="instagram_1_" data-name="instagram (1)" d="M13.523,10.665a2.858,2.858,0,1,0,2.858,2.858A2.858,2.858,0,0,0,13.523,10.665ZM24.965,7.842a7.525,7.525,0,0,0-.484-2.6,4.609,4.609,0,0,0-2.673-2.673,7.525,7.525,0,0,0-2.6-.484C17.717,2,17.28,2,13.523,2s-4.194,0-5.681.081a7.525,7.525,0,0,0-2.6.484A4.609,4.609,0,0,0,2.565,5.238a7.525,7.525,0,0,0-.484,2.6C2,9.329,2,9.767,2,13.523s0,4.194.081,5.681a7.9,7.9,0,0,0,.484,2.616,4.54,4.54,0,0,0,1.049,1.613,4.482,4.482,0,0,0,1.625,1.049,7.524,7.524,0,0,0,2.6.484c1.486.081,1.924.081,5.681.081s4.194,0,5.681-.081a7.525,7.525,0,0,0,2.6-.484,4.482,4.482,0,0,0,1.625-1.049,4.54,4.54,0,0,0,1.049-1.613,7.605,7.605,0,0,0,.484-2.616c.081-1.486.081-1.924.081-5.681s0-4.194-.081-5.681Zm-2.927,9.218a6.6,6.6,0,0,1-.449,2.074,4.448,4.448,0,0,1-2.454,2.454,6.6,6.6,0,0,1-2.086.4H10a6.6,6.6,0,0,1-2.086-.4,4.289,4.289,0,0,1-2.454-2.454,6.326,6.326,0,0,1-.392-2.086c0-.91,0-1.152,0-3.526V10a6.326,6.326,0,0,1,.392-2.086A4.045,4.045,0,0,1,6.448,6.4a4.137,4.137,0,0,1,1.463-.945A6.6,6.6,0,0,1,10,5.054h7.052a6.6,6.6,0,0,1,2.086.4,4.289,4.289,0,0,1,2.454,2.454,6.6,6.6,0,0,1,.4,2.086c0,.91,0,1.152,0,3.526s.081,2.616.046,3.526ZM20.195,8.487A2.742,2.742,0,0,0,18.57,6.863a4.609,4.609,0,0,0-1.59-.254H10.066a4.609,4.609,0,0,0-1.59.3A2.742,2.742,0,0,0,6.851,8.476a4.92,4.92,0,0,0-.242,1.59V16.98a4.92,4.92,0,0,0,.3,1.59,2.742,2.742,0,0,0,1.625,1.625,4.92,4.92,0,0,0,1.533.3H16.98a4.609,4.609,0,0,0,1.59-.3,2.742,2.742,0,0,0,1.625-1.625,4.609,4.609,0,0,0,.3-1.59V10.066a4.356,4.356,0,0,0-.3-1.59Zm-6.672,9.437a4.39,4.39,0,0,1-4.39-4.4h0a4.4,4.4,0,1,1,4.39,4.4Zm4.609-7.939a1.037,1.037,0,0,1,0-2.063h0a1.037,1.037,0,0,1,0,2.063Z" transform="translate(-2 -2)" fill="#13243c" />
                                                    </svg>
                                                </span>
                                            </Link>
                                        </div>
                                        <div className="ss_item">
                                            <Link to="Components/Components.html">
                                                <span>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 27.057 25.856">
                                                        <path id="linkedin" d="M0,8A2.812,2.812,0,0,1,.914,5.849,3.353,3.353,0,0,1,3.291,5a3.253,3.253,0,0,1,2.324.836,2.946,2.946,0,0,1,.914,2.246,2.75,2.75,0,0,1-.888,2.089,3.359,3.359,0,0,1-2.4.862H3.212a3.2,3.2,0,0,1-2.324-.862A2.9,2.9,0,0,1,0,8ZM.34,30.856V13.41h5.8V30.856Zm9.01,0h5.8V21.114A3.4,3.4,0,0,1,16.467,18.2a2.838,2.838,0,0,1,1.867-.614q2.925,0,2.925,3.944v9.324h5.8v-10a8.406,8.406,0,0,0-1.828-5.863,6.236,6.236,0,0,0-4.832-2,5.927,5.927,0,0,0-5.249,2.9v.052h-.026l.026-.052V13.41H9.35q.052.836.052,5.2T9.35,30.856Z" transform="translate(0 -5)" fill="#13243c" />
                                                    </svg>
                                                </span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>






                        </div>
                        <div className="col-lg-6">
                            <div className="body_form">
                                <p><span>Great vision without great people is irrelevant.</span>
                                    <span>Let's work together.</span>
                                </p>
                                <Form>
                                    <fieldset>
                                        <Form.Group className="mb-3">
                                            <Form.Control type="text" placeholder="Name" />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Control type="text" placeholder="Email" />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                            <Form.Control as="textarea" rows={3} placeholder='Message' />
                                        </Form.Group>
                                        <div className="col-md-4">
                                            <Button type="submit">Submit</Button>
                                        </div>
                                    </fieldset>
                                </Form>
                            </div>
                        </div>
                    </div> */}




                </div>
            </div>
            <Footer />
    </>
  )
}

export default Contact